$(function () {
  // $('.dashboard div.cards').addClass('row');
  // $('.dashboard div.cards > a').each(function () {
  //   $(this).wrap('<div class="col-lg-3 col-md-4 col-sm-6 mb-3">').wrap('<div class="card text-center">').wrap('<div class="card-body">');
  //   return $(this).parent().parent().append($('<a>').attr('href', $(this).attr('href')).text($(this).find('img').attr('alt')).wrap('<div class="card-header">').parent());
  // });

  // $('article > h1').wrap('<header>');
  // $('article.article > div.row > div:first-child > h1').wrap('<header>');

  // $('article > header > nav').wrapInner('<ol class="breadcrumb">');
  // $('ol.breadcrumb a').wrap('<li class="breadcrumb-item">');
  // $('ol.breadcrumb li:last-of-type').addClass('active');

  $('fieldset.choices').addClass('container');
  $('fieldset.choices ol.choices-group li.choice').addClass('form-check');
  $('fieldset.choices ol.choices-group li.choice label').addClass('form-check-label');
  $('fieldset.choices ol.choices-group li.choice label input').addClass('form-check-input');

  $('.formtastic li.image').addClass('form-group');
  $('.formtastic li.select').addClass('form-group');
  $('.formtastic li.stringish').addClass('form-group');
  $('.formtastic li.text').addClass('form-group');
  $('.formtastic li.select select').addClass('form-control');
  $('.formtastic li.stringish:not(.range) input').addClass('form-control');
  $('.formtastic textarea').addClass('form-control');
  $('.formtastic li.range input').addClass('custom-range');
  $('.formtastic li.boolean').addClass('form-group').addClass('form-check');
  $('.formtastic li.boolean label').addClass('form-check-label');
  $('.formtastic li.boolean input').addClass('form-check-input');
  $('.formtastic li.check_boxes fieldset.choices ol.choices-group li.choice').addClass('form-check');
  $('.formtastic li.check_boxes fieldset.choices ol.choices-group li.choice input[type="checkbox"]').addClass('form-check-input');
  $('.formtastic li.check_boxes fieldset.choices ol.choices-group li.choice label').addClass('form-check-label');
  $('.formtastic input[data-type="ssn4"]').wrap($('<div>').addClass('form-group')).wrap($('<div>').addClass('input-group mb-2')).before($('<div>').addClass('input-group-prepend').html($('<span>').addClass('input-group-text').text('XXX-XX-')));
  $('.formtastic li.error').addClass('has-danger');
  $('.formtastic li.error input').addClass('is-invalid');
  $('.formtastic li.error select').addClass('is-invalid');
  $('.formtastic li.error textarea').addClass('is-invalid');
  $('.formtastic li.error .inline-errors').addClass('invalid-feedback');

  $('.formtastic li.file').addClass('custom-file btn-file');
  $('.formtastic li.file input').addClass('custom-file-input');
  $('.formtastic li.file label').addClass('custom-file-label');

  $('.formtastic input[type="number"].money').attr('min', '0').wrap('<span class="input-group mb-3">').before('<span class="input-group-prepend"><span class="input-group-text">$</span></span>').after('<span class="input-group-append"><span class="input-group-text">.00</span></span>');

  // $('.formtastic fieldset.actions ol li.action input').addClass('btn btn-primary');
  // $('.formtastic fieldset.actions ol li.action button').addClass('btn btn-primary');

  $(document).on('change', '.custom-file-input', function () {
      const input = $(this);
      const label = input.val().replace(/\\/g, '/').replace(/.*\//, '');
      input.trigger('fileselect', [label]);
  });

  $('.custom-file-input').on('fileselect', function (event, label) {
      $(this).parents('li.file').find('label').text(label);
      // if (this.files && this.files[0]) {
      //     const reader = new FileReader();
      //     reader.onload = function (e) {
      //         $('#img-upload').attr('src', e.target.result);
      //     };
      //     reader.readAsDataURL(this.files[0]);
      // } else {
      //     $('#img-upload').attr('src', '');
      // }
  });

  $('table').addClass('table');

  return true;
});
